import { render, staticRenderFns } from "./generate.vue?vue&type=template&id=4a1f2288&scoped=true&"
import script from "./generate.vue?vue&type=script&lang=js&"
export * from "./generate.vue?vue&type=script&lang=js&"
import style0 from "./generate.vue?vue&type=style&index=0&id=4a1f2288&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a1f2288",
  null
  
)

export default component.exports