<template>
  <div class="codeID">
    <van-nav-bar
        title="证件照"
        left-arrow fixed
        @click-left="goBack"
        @click-right="isDropdownMenu= !isDropdownMenu"
    >
    <template #right>
                <van-icon size="22" name="apps-o"/>
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/')">
                    <span>首页</span>
                </div>
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>
    <div class="content-box">

      <div class="photo-box">
<!--        <div class="title">保存后无水印</div>-->
        <img class="logo-box" :src="preview_url"/>
        <div v-if="active===1">
          <van-radio-group v-model="radio" direction="horizontal">
            <van-row type="flex" justify="center">
              <van-col span="6">
                <van-radio name="white" shape="square" class="white" checked-color="#fff" @click="change"></van-radio>
              </van-col>
              <van-col span="6">
                <van-radio name="blue" shape="square" class="blue" checked-color="#1989fa" @click="change"></van-radio>
              </van-col>
              <van-col span="6">
                <van-radio name="red" shape="square" class="red" checked-color="#ff0000" @click="change"></van-radio>
              </van-col>
            </van-row>
          </van-radio-group>
        </div>
        <div v-if="active===2">
          <van-row class="myWarp">
            <van-col span="12">
              <div class="myEl" @click="hdFn(0)">
                <img v-if="hd === 0" src="@/assets/all_Icon/drawable-xxhdpi/certi_nonelight.png">
                <img v-else src="@/assets/all_Icon/drawable-xxhdpi/certi_none.png">
                <p>无</p>
              </div>
            </van-col>
            <van-col span="12">
              <div class="myEl" @click="hdFn(1)">
                <img v-if="hd === 1" src="@/assets/all_Icon/drawable-xxhdpi/certi_beautylight_big.png">
                <img v-else src="@/assets/all_Icon/drawable-xxhdpi/certi_beauty_big.png">
                <p>高清美颜</p>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>

      <div class="type">
        <p :class="active===1?'active':''" @click="activeFn(1)">
          <img v-if="active===1" src="@/assets/all_Icon/drawable-xxhdpi/certi_bglight.png">
          <img v-else src="@/assets/all_Icon/drawable-xxhdpi/certi_bgnormal.png">
          背景</p>
        <p :class="active===2?'active':''" @click="activeFn(2)">
          <img v-if="active===2" src="@/assets/all_Icon/drawable-xxhdpi/certi_beautylight.png">
          <img v-else src="@/assets/all_Icon/drawable-xxhdpi/certi_beauty.png">
          高清美颜</p>
      </div>

      <div class="desc">
        <p>规格详情</p>
        <div>
          <span>像素尺寸：</span>
          <span>{{ listData.width }}*{{ listData.height }}PX</span>
          <span style="margin-left: 20px;">文件大小：</span>
          <span>{{ listData.fileSize }}</span>
        </div>
        <div>
          <span>背景色：</span>
          <span class="activeBg" :class="'activeBg'+radio"></span>
        </div>
      </div>

      <van-button v-if="frequency" class="submit" round type="info" size="small" @click="submit">免费生成无水印证件照 （剩余{{ frequency }}次）</van-button>
      <van-button v-else class="submit" round type="info" size="small" @click="submit">¥{{this.listData.price}}生成无水印证件照</van-button>
    </div>
  </div>
</template>

<script>
import {getFreeDownloadNumber, makePhotoList, makePhotoOrder,downLoadFree,preview} from '@/api/photo.js';
export default {
  name: "generate",
  data() {
    return {
      isDropdownMenu:false,
      radio: 'blue',
      active: 1,
      listData:{
        price:''
      },
      hd:1,
      frequency: 0,
      makePhotoBase64: '',
      colorType: {
        white: '#ffffff',
        blue: '#1989fa',
        red: '#ff0000',
      },
      activeList:{
        data:{
          filenames:[
            {
              preview_url:""
            }
          ]
        }
      },
      preview_url:'',
      text: '¥3.9生成无水印证件照'//免费生成无水印证件照 （剩余1次）
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    downApp(){
      window.location.href = 'https://www.saen.com/app/';
    },
    goBack() {
      this.$router.go(-1);
    },
    activeFn(type) {
      this.active = type;
      if(type===1){
        this.hd = 0;
      }else {
        this.hd = 1;
      }
    },
    change() {
      this.makePhoto();
    },
    hdFn(val) {
      this.hd = val;
      this.makePhoto();
    },
    get() {
      getFreeDownloadNumber().then(res=>{
        console.log(res);
        this.frequency = res.data.data;
      })
    },
    submit() {
      if(this.frequency>0) {
        
         var that = this;
          window.sessionStorage.setItem("orderpayFlag", "2");
          let data = new FormData();
          data.append("width", this.listData.width);
          data.append("height", this.listData.height);
          data.append("identificationPhotoId", this.listData.id);
          data.append("source", "h5");
          data.append("bgColor", this.activeList.data.filenames[0].bg_color);
        
          data.append("payWay",0); //支付方式支付方式 0-免费 1-微信 2-支付宝 3-网银 4-余额

          makePhotoOrder(data).then((res) => {
            localStorage.setItem('orderNo',res.data.data.orderNo);
            that.$router.push({
              path: "/downloadPhoto",
              query:{
                type:'intPay'
              }
            });
          });
      }else {
        this.$router.push({
          path:'/payment'
        })
      }
    },
    getBase64(url, callback) {
      var Img = new Image(),
      dataURL = '';
      Img.src = url + '?v=' + Math.random();
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.onload = function() {
        var canvas = document.createElement('canvas'),
        width = Img.width,
        height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        return callback ? callback(dataURL) : null;
      };
    },
    makePhoto(){
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let obj = new FormData()
      obj.append('file',this.makePhotoBase64)
      obj.append('width',this.listData.width)
      obj.append('height',this.listData.height)
      obj.append('bgColor',this.colorType[this.radio])
      obj.append('colorName','saen')
      obj.append('fairLevel',this.hd)
      obj.append('photoId',this.listData.id)
      makePhotoList(obj).then((res)=>{
        
        if(res.data.code == 0){
          
          this.get();
          localStorage.setItem('detail_makePhoto',JSON.stringify(res.data.data));
          this.activeList = JSON.parse(localStorage.getItem('detail_makePhoto'));
          preview(this.activeList.data.filenames[0].preview_img_name).then((res)=>{
            this.preview_url = res.data.data;
            this.$toast.clear();
          })
        }
      })
    },
    initUrl(name){
      // let data = new FormData();
      // data.append('previewImgName',name);
      // let data = {
      //   previewImgName:name
      // }
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      preview(name).then((res)=>{
        console.log("res",res);
        this.preview_url = res.data.data;
        this.$toast.clear();
      })

    },
    init(){
      this.get();
      this.activeList = JSON.parse(localStorage.getItem('detail_makePhoto'));
      
      
      this.listData = JSON.parse(localStorage.getItem('shotTypeDetail'));
      this.makePhotoBase64 = sessionStorage.getItem('makePhotoBase64');
      if(this.activeList.data.filenames[0].bg_color === '#FFFFFF') {
        this.radio = 'white';
      }else if(this.activeList.data.filenames[0].bg_color === '#1989fa') {
        this.radio = 'blue';
      }else if(this.activeList.data.filenames[0].bg_color === '#ff0000') {
        this.radio = 'red';
      }
      this.$nextTick(()=>{
        this.initUrl(this.activeList.data.filenames[0].preview_img_name);
      })
    }
  }
}
</script>

<style lang="less" scoped>
.downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            right: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 100px;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
.codeID {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F8F8F8;

  .content-box {
    width: 100%;
    height: calc(100vh - 46px);
    margin: 46px auto 0;
    padding: 20px 0;

    .photo-box{
      width: 178px;
      margin: 0 auto;
      position: relative;

      .myWarp{
        width: 100%;

        .myEl{
          margin: 20px 0 10px;
          width: 100%;
          text-align: center;
          line-height: 46px;
          img{
            width: 27px;
            height: 29px;
            margin: 0 auto;
          }
        }
      }

      img{
        display: block;
        width: 178px;
        height: 200px;
      }

      .title{
        position: absolute;
        top: 160px;
        left: 50px;
        opacity: .7;
        background: #8D8D8D;
        display: inline-block;
        width: 78px;
        color: #fff;
        padding: 2px 10px;
      }

      .van-radio-group{
        margin: 40px auto 20px;
        .van-row{
          width: 100%;
        }
        .van-radio{
          justify-content: center;
        }
      }

      .white{
        /deep/ .van-radio__icon{
          background: #fff;
        }
        /deep/ .van-radio__icon--checked {
          .van-icon{
            color: #1989fa;
          }
        }
      }
      .blue{
        /deep/ .van-radio__icon{
          background: #1989fa;
        }
      }
      .red{
        /deep/ .van-radio__icon{
          background: #ff0000;
        }
      }
    }

    .type{
      padding: 0 60px;
      p{
        width: 49%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 5px;
        display: inline-block;
        background: #fff;
        border: 1px solid transparent;
      }
      .active{
        border: 1px solid #1989fa;
        color: #1989fa;
      }
    }

    .desc{
      margin: 20px auto;
      border-radius: 5px;
      width: calc(100% - 80px);
      background: #fff;
      padding: 10px 20px;

      p{
        color: #ffb801;
      }
      p,div{
        height: 24px;
        line-height: 24px;
      }
      .activeBg{
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        vertical-align: sub;
        border: 1px solid #E5E5E5;
      }
      .activeBgwhite{
        background: #fff;
      }
      .activeBgblue{
        background: #1989fa;
      }
      .activeBgred{
        background: #ff0000;
      }
    }

    .submit{
      width: 80%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
